ion-menu ion-content {
  --background: var(--ion-color-primary);
}
ion-menu {
  --ion-item-background: --ion-color-primary;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 8px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu ion-list.sidelist--top {
  margin-top: -3px;
  padding-top: 0;
}

ion-menu ion-list.sidelist ion-list-header {
  color: var(--ion-color-primary-contrast);
  font-size: 16px;
  font-weight: 500;
}

ion-menu ion-list.sidelist ion-item {
  --color: --ion-color-primary-contrast;
  color: var(--ion-color-primary-contrast);
  opacity: 0.6;
  font-size: 15px;
}

ion-menu ion-list.sidelist ion-item.selected {
  color: var(--ion-color-primary-contrast);
  opacity: 1;
}

ion-menu ion-list.sidelist--top ion-list-header {
  font-size: 20px;
  font-weight: 500;
}

ion-menu.md ion-list.sidelist {
  margin-left: -8px;
  margin-right: -8px;
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
}

ion-menu.md ion-list.sidelist ion-list-header {
  min-height: 20px;
  margin-bottom: 10px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-contrast-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary-contrast);
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-primary-contrast);
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 8px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: var(--ion-color-primary-contrast);
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary-contrast);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-note {
  display: inline-block;
  font-size: 14px;
  color: var(--ion-color-primary-contrast);
}
