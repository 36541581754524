.center-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  padding: 15px;
  transform: translateY(-50%);
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-container-inner {
  flex: 1;
  max-width: 580px;
}
